import React, { useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  Home,
  MapPin,
  Clock,
  Calendar,
} from "lucide-react";
import MasjidSelectionModal from "./MasjidSelectionModal";
import CustomTimetableModal from "./CustomTimetableModal";

const PrayerSettings = ({ onSelectionChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("none");
  const [pendingOption, setPendingOption] = useState(null);
  const [showMasjidModal, setShowMasjidModal] = useState(false);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [selectedMasjid, setSelectedMasjid] = useState(null);
  const [hasUploadedTimetable, setHasUploadedTimetable] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (value) => {
    setPendingOption(value);

    if (value === "masjid") {
      setShowMasjidModal(true);
    } else if (value === "custom") {
      setShowCustomModal(true);
    } else {
      // For options without modals, apply immediately
      finalizeSelection(value);
    }
  };

  const finalizeSelection = (value) => {
    setSelectedOption(value);
    setPendingOption(null);
    onSelectionChange(value);
  };

  const handleMasjidSelect = (masjid) => {
    setSelectedMasjid(masjid);
    setShowMasjidModal(false);
    finalizeSelection("masjid");
  };

  const handleMasjidModalClose = () => {
    setShowMasjidModal(false);
    setPendingOption(null);
  };

  const handleCustomClose = () => {
    setShowCustomModal(false);
    setHasUploadedTimetable(true);
    finalizeSelection("custom");
  };

  const handleCustomModalClose = () => {
    setShowCustomModal(false);
    setPendingOption(null);
  };

  const getOptionIcon = (option) => {
    switch (option) {
      case "masjid":
        return <Home size={16} />;
      case "geolocation":
        return <MapPin size={16} />;
      case "custom":
        return <Clock size={16} />;
      case "none":
        return <Calendar size={16} />;
      default:
        return null;
    }
  };

  const options = [
    { value: "masjid", label: "Masjid Selection" },
    { value: "geolocation", label: "Geolocation" },
    { value: "custom", label: "Custom Timetable" },
    { value: "none", label: "None" },
  ];

  return (
    <div>
      {/* Main Toggle Button */}
      <button
        onClick={toggleDropdown}
        className="w-full bg-gray-800/30 hover:bg-gray-800/50 text-white rounded-lg p-3 flex items-center justify-between transition-colors duration-200"
      >
        <div className="flex items-center">
          {getOptionIcon(selectedOption)}
          <span className="text-sm ml-2">
            {selectedOption === "none"
              ? "Prayer Times"
              : options.find((opt) => opt.value === selectedOption)?.label}
          </span>
        </div>
        {isOpen ? (
          <ChevronUp size={16} className="text-gray-400" />
        ) : (
          <ChevronDown size={16} className="text-gray-400" />
        )}
      </button>

      {/* Dropdown Options */}
      {isOpen && (
        <div className="mt-2 space-y-2">
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => handleOptionSelect(option.value)}
              className={`w-full p-3 rounded-lg flex items-center justify-between transition-colors duration-200
                ${
                  selectedOption === option.value
                    ? "bg-indigo-600 text-white"
                    : "bg-gray-800/30 text-gray-300 hover:bg-gray-800/50"
                }`}
            >
              <div className="flex items-center">
                {getOptionIcon(option.value)}
                <span className="ml-2 text-sm">{option.label}</span>
              </div>
              {option.value === "masjid" &&
                selectedMasjid &&
                selectedOption === "masjid" && (
                  <span className="text-xs text-gray-400">
                    {selectedMasjid.name}
                  </span>
                )}
              {option.value === "custom" &&
                hasUploadedTimetable &&
                selectedOption === "custom" && (
                  <span className="text-xs text-gray-400">Uploaded</span>
                )}
            </button>
          ))}
        </div>
      )}

      {/* Modals */}
      {showMasjidModal && (
        <MasjidSelectionModal
          onClose={handleMasjidModalClose}
          onSelect={handleMasjidSelect}
        />
      )}

      {showCustomModal && (
        <CustomTimetableModal
          onClose={handleCustomModalClose}
          onSave={handleCustomClose}
        />
      )}
    </div>
  );
};

export default PrayerSettings;
