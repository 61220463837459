import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeft,
  Heart,
  Target,
  BookOpen,
  Plus,
  Edit3,
  Trash2,
  AlertCircle,
} from "lucide-react";

const GoalsTracking = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("intentions");
  const [goals, setGoals] = useState([]);
  const [reflections, setReflections] = useState([]);
  const [showIntentionGuide, setShowIntentionGuide] = useState(true);

  const [newGoal, setNewGoal] = useState({
    title: "",
    category: "",
    intention: "",
    impact: "",
    deadline: "",
    progress: 0,
    reflections: [],
    createdAt: null,
    lastReflection: null,
  });

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("goalsData")) || {};
    setGoals(storedData.goals || []);
    setReflections(storedData.reflections || []);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "goalsData",
      JSON.stringify({
        goals,
        reflections,
      })
    );
  }, [goals, reflections]);

  const handleAddGoal = () => {
    if (newGoal.title && newGoal.intention) {
      setGoals([
        ...goals,
        {
          ...newGoal,
          id: Date.now(),
          createdAt: new Date().toISOString(),
          progress: 0,
          reflections: [],
        },
      ]);
      setNewGoal({
        title: "",
        category: "",
        intention: "",
        impact: "",
        deadline: "",
        progress: 0,
        reflections: [],
      });
    }
  };

  const renderHeader = () => (
    <header className="flex justify-between items-center mb-6 text-white">
      <ChevronLeft
        size={24}
        className="cursor-pointer"
        onClick={() => navigate("/")}
      />
      <h1 className="text-xl font-bold">Intentional Growth</h1>
      <div className="w-6" />
    </header>
  );

  const renderTabNavigation = () => (
    <div className="flex mb-6 bg-gray-800 rounded-lg overflow-hidden">
      {[
        { id: "intentions", icon: <Heart size={16} /> },
        { id: "goals", icon: <Target size={16} /> },
        { id: "reflections", icon: <BookOpen size={16} /> },
      ].map((tab) => (
        <button
          key={tab.id}
          className={`flex-1 py-3 flex items-center justify-center space-x-2
            ${
              activeTab === tab.id
                ? "bg-indigo-600 text-white"
                : "text-gray-400 hover:bg-gray-700"
            }`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.icon}
          <span className="capitalize">{tab.id}</span>
        </button>
      ))}
    </div>
  );

  const renderIntentionsGuide = () => (
    <div className="bg-gray-800 rounded-lg p-6 mb-6 text-white">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-lg font-semibold">Setting Meaningful Intentions</h3>
        <button
          onClick={() => setShowIntentionGuide(false)}
          className="text-gray-400 hover:text-white"
        >
          <AlertCircle size={20} />
        </button>
      </div>
      <div className="space-y-4 text-gray-300">
        <p>Before setting goals, reflect on:</p>
        <ol className="list-decimal list-inside space-y-2 pl-4">
          <li>What truly matters to you and why?</li>
          <li>How do your goals align with your values?</li>
          <li>What positive impact will these changes create?</li>
          <li>Who will benefit from your growth?</li>
        </ol>
      </div>
    </div>
  );

  const renderIntentionSetting = () => (
    <div className="space-y-4">
      {showIntentionGuide && renderIntentionsGuide()}

      <div className="bg-gray-800 rounded-lg p-6">
        <h3 className="text-lg font-semibold text-white mb-4">
          New Intention & Goal
        </h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              What matters to you?
            </label>
            <textarea
              className="w-full p-3 bg-gray-700 rounded-lg border border-gray-600 text-white resize-none focus:ring-2 focus:ring-indigo-500"
              placeholder="I want to achieve this because..."
              value={newGoal.intention}
              onChange={(e) =>
                setNewGoal({ ...newGoal, intention: e.target.value })
              }
              rows={3}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Specific Goal
            </label>
            <input
              type="text"
              className="w-full p-3 bg-gray-700 rounded-lg border border-gray-600 text-white focus:ring-2 focus:ring-indigo-500"
              placeholder="What specific goal will support this intention?"
              value={newGoal.title}
              onChange={(e) =>
                setNewGoal({ ...newGoal, title: e.target.value })
              }
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Expected Positive Impact
            </label>
            <textarea
              className="w-full p-3 bg-gray-700 rounded-lg border border-gray-600 text-white resize-none focus:ring-2 focus:ring-indigo-500"
              placeholder="How will this goal positively impact your life and others?"
              value={newGoal.impact}
              onChange={(e) =>
                setNewGoal({ ...newGoal, impact: e.target.value })
              }
              rows={2}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Category
              </label>
              <select
                className="w-full p-3 bg-gray-700 rounded-lg border border-gray-600 text-white focus:ring-2 focus:ring-indigo-500"
                value={newGoal.category}
                onChange={(e) =>
                  setNewGoal({ ...newGoal, category: e.target.value })
                }
              >
                <option value="">Select Category</option>
                <option value="spiritual">Spiritual Growth</option>
                <option value="health">Health & Wellness</option>
                <option value="relationships">Relationships</option>
                <option value="personal">Personal Development</option>
                <option value="career">Career Growth</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-1">
                Target Date
              </label>
              <input
                type="date"
                className="w-full p-3 bg-gray-700 rounded-lg border border-gray-600 text-white focus:ring-2 focus:ring-indigo-500"
                value={newGoal.deadline}
                onChange={(e) =>
                  setNewGoal({ ...newGoal, deadline: e.target.value })
                }
              />
            </div>
          </div>

          <button
            onClick={handleAddGoal}
            className="w-full py-3 bg-indigo-600 text-white rounded-lg flex items-center justify-center space-x-2 hover:bg-indigo-700 transition duration-200"
          >
            <Plus size={20} />
            <span>Create Intentional Goal</span>
          </button>
        </div>
      </div>
    </div>
  );

  const renderGoalsList = () => (
    <div className="space-y-4">
      {goals.map((goal) => (
        <div key={goal.id} className="bg-gray-800 rounded-lg p-6 text-white">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h3 className="font-semibold text-lg">{goal.title}</h3>
              <span className="text-sm text-gray-400">{goal.category}</span>
            </div>
            <div className="flex space-x-2">
              <button className="p-2 hover:bg-gray-700 rounded-full">
                <Edit3 size={16} />
              </button>
              <button className="p-2 hover:bg-gray-700 rounded-full text-red-400">
                <Trash2 size={16} />
              </button>
            </div>
          </div>

          <div className="mb-4">
            <div className="text-sm text-gray-300 mb-2">Intention:</div>
            <p className="text-gray-400 italic">
              &ldquo;{goal.intention}&rdquo;
            </p>
          </div>

          <div className="mb-4">
            <div className="text-sm text-gray-300 mb-2">Impact:</div>
            <p className="text-gray-400">{goal.impact}</p>
          </div>

          <div className="mb-4">
            <div className="flex justify-between items-center text-sm text-gray-300 mb-2">
              <span>Progress</span>
              <span>{goal.progress}%</span>
            </div>
            <div className="w-full h-2 bg-gray-700 rounded-full overflow-hidden">
              <div
                className="h-full bg-indigo-600 transition-all duration-300"
                style={{ width: `${goal.progress}%` }}
              />
            </div>
          </div>

          <div className="flex justify-between items-center">
            <button
              className="px-3 py-1 bg-gray-700 rounded-full text-sm hover:bg-gray-600"
              onClick={() => {
                /* Add reflection */
              }}
            >
              Reflect
            </button>
            <div className="text-sm text-gray-400">
              Target: {new Date(goal.deadline).toLocaleDateString()}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderReflectionJournal = () => (
    <div className="space-y-4">
      <div className="bg-gray-800 rounded-lg p-6">
        <h3 className="text-lg font-semibold text-white mb-4">
          Growth Journal
        </h3>
        <textarea
          className="w-full p-3 bg-gray-700 rounded-lg border border-gray-600 text-white resize-none focus:ring-2 focus:ring-indigo-500 mb-4"
          placeholder="Reflect on your progress and learnings..."
          rows={4}
        />
        <button className="w-full py-3 bg-indigo-600 text-white rounded-lg flex items-center justify-center space-x-2 hover:bg-indigo-700 transition duration-200">
          <Plus size={20} />
          <span>Add Reflection</span>
        </button>
      </div>
      <div className="bg-gray-800 rounded-lg p-6">
        <h3 className="text-lg font-semibold text-white mb-4">
          Previous Reflections
        </h3>
        <div className="space-y-4">
          {reflections.map((reflection) => (
            <div key={reflection.id} className="border-b border-gray-700 pb-4">
              <div className="text-sm text-gray-400 mb-2">
                {new Date(reflection.date).toLocaleDateString()}
              </div>
              <p className="text-white">{reflection.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="flex flex-col min-h-screen p-4"
      style={{ backgroundColor: "#041A34" }}
    >
      {renderHeader()}
      {renderTabNavigation()}
      <div className="flex-1 overflow-y-auto">
        {activeTab === "intentions" && renderIntentionSetting()}
        {activeTab === "goals" && renderGoalsList()}
        {activeTab === "reflections" && renderReflectionJournal()}
      </div>
    </div>
  );
};

export default GoalsTracking;
