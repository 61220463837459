import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const CalendarHeader = ({ currentDate, changeWeek }) => {
  const days = ["M", "T", "W", "T", "F", "S", "S"];
  const currentDay = currentDate.getDate();
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDay - currentDate.getDay() + 1); // Monday

  return (
    <header
      className="p-4"
      style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
    >
      <div className="flex justify-between items-center mb-2">
        <a href="https://solah.app/" target="_blank" rel="noopener noreferrer">
          <img
            src="/solah-logo.png"
            alt="Solah App Logo"
            style={{ height: "32px", width: "auto" }}
          />
        </a>
        <div className="flex items-center space-x-2">
          <h2 className="text-lg font-semibold text-white">
            {currentDate.toLocaleString("default", { month: "short" })}
          </h2>
          <span className="text-lg font-semibold text-white">
            {currentDate.getFullYear()}
          </span>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <ChevronLeft
          size={24}
          className="text-gray-400 cursor-pointer mr-2"
          onClick={() => changeWeek(-1)}
        />
        <div className="flex-1 flex justify-between px-2">
          {days.map((day, index) => {
            const date = new Date(startOfWeek);
            date.setDate(startOfWeek.getDate() + index);
            const isCurrentDay =
              date.getDate() === currentDay &&
              date.getMonth() === currentDate.getMonth();
            return (
              <div key={day} className="text-center">
                <div className="text-xs text-gray-400 mb-1">{day}</div>
                <div
                  className={`w-6 h-6 flex items-center justify-center rounded-full 
                  ${isCurrentDay ? "bg-white bg-opacity-30" : ""}`}
                >
                  <span
                    className={`text-xs ${
                      isCurrentDay ? "text-white" : "text-gray-400"
                    }`}
                  >
                    {date.getDate()}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <ChevronRight
          size={24}
          className="text-gray-400 cursor-pointer ml-2"
          onClick={() => changeWeek(1)}
        />
      </div>
    </header>
  );
};

export default CalendarHeader;
