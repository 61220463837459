import React, { useState, useEffect } from "react";
import {
  X,
  ArrowRight,
  Loader,
  Calendar,
  Mail,
  AlertCircle,
  Check,
  RefreshCcw,
  Sparkles,
} from "lucide-react";
import aiIcon from "../utils/icon.png";

const AIAssistant = ({
  onProcessingStateChange,
  onEventsChange,
  onStepChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [processingSteps, setProcessingSteps] = useState([]);
  const [showResponse, setShowResponse] = useState(false);
  const [showSparkles, setShowSparkles] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setProcessingSteps([]);
        setCurrentAction(null);
        setShowResponse(false);
        setShowSparkles(false);
        setUserInput("");
      }, 300);
    }
  }, [isOpen]);

  const generateEvents = () => {
    const currentHour = new Date().getHours();
    return [
      {
        id: `ai_1_${Date.now()}`,
        title: "Team Sync",
        start: `${String(currentHour + 1).padStart(2, "0")}:00`,
        end: `${String(currentHour + 2).padStart(2, "0")}:00`,
        color: "bg-blue-500",
      },
      {
        id: `ai_2_${Date.now()}`,
        title: "Focus Time",
        start: `${String(currentHour + 2).padStart(2, "0")}:30`,
        end: `${String(currentHour + 4).padStart(2, "0")}:00`,
        color: "bg-purple-500",
      },
      {
        id: `ai_3_${Date.now()}`,
        title: "Project Review",
        start: `${String(currentHour + 4).padStart(2, "0")}:00`,
        end: `${String(currentHour + 5).padStart(2, "0")}:00`,
        color: "bg-indigo-500",
      },
    ];
  };

  const resetState = () => {
    setIsOpen(false);
    onProcessingStateChange(false);
    onStepChange(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInput.trim() || isProcessing) return;

    setProcessingSteps([]);
    setIsProcessing(true);
    onProcessingStateChange(true);

    const steps = [
      {
        id: 1,
        text: "Analyzing your calendar...",
        icon: AlertCircle,
        color: "text-yellow-400",
        duration: 1500,
      },
      {
        id: 2,
        text: "Finding optimal schedule...",
        icon: Calendar,
        color: "text-blue-400",
        duration: 2000,
      },
      {
        id: 3,
        text: "Rescheduling events...",
        icon: RefreshCcw,
        color: "text-green-400",
        duration: 2000,
      },
      {
        id: 4,
        text: "Finalizing changes...",
        icon: Mail,
        color: "text-purple-400",
        duration: 1500,
      },
    ];

    const newEvents = generateEvents();

    for (const step of steps) {
      setCurrentAction(step);
      onStepChange(step.id);
      setProcessingSteps((prev) => [...prev, step]);

      if (step.id === 3) {
        onEventsChange(newEvents);
      }

      await new Promise((resolve) => setTimeout(resolve, step.duration));
    }

    await new Promise((resolve) => setTimeout(resolve, 800));

    setIsProcessing(false);
    setShowResponse(true);
    setShowSparkles(true);

    await new Promise((resolve) => setTimeout(resolve, 1000));
    onProcessingStateChange(false);
    onStepChange(null);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-6 right-6 z-50 w-12 h-12 rounded-full flex items-center justify-center shadow-xl hover:shadow-2xl transform hover:scale-110 transition-all duration-300"
        style={{
          backgroundColor: "rgba(79, 70, 229, 0.2)",
          border: "1px solid rgba(99, 102, 241, 0.4)",
        }}
      >
        <div className="w-8 h-8 relative">
          <img
            src={aiIcon}
            alt="AI Assistant"
            className="w-full h-full object-contain"
          />
        </div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-gray-900 rounded-xl w-full max-w-md m-4 shadow-2xl border border-gray-800">
            <div className="flex justify-between items-center p-6 border-b border-gray-800">
              <div className="flex items-center space-x-3">
                <div className="w-8 h-8 relative">
                  <img
                    src={aiIcon}
                    alt="AI Assistant"
                    className="w-full h-full object-contain"
                  />
                </div>
                <h2 className="text-xl font-semibold text-white">
                  AI Assistant
                </h2>
              </div>
              <button
                onClick={resetState}
                className="text-gray-400 hover:text-white transition-colors"
              >
                <X size={24} />
              </button>
            </div>

            <div className="p-6">
              {!showResponse ? (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="text-lg text-white mb-2 block">
                      How can I help with your schedule?
                    </label>
                    <input
                      type="text"
                      value={userInput}
                      onChange={(e) => setUserInput(e.target.value)}
                      placeholder="e.g., Reorganize my meetings for today"
                      className="w-full p-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      disabled={isProcessing}
                    />
                  </div>

                  {processingSteps.length > 0 && (
                    <div className="space-y-2">
                      {processingSteps.map((step) => {
                        const Icon = step.icon;
                        const isActive = currentAction?.id === step.id;
                        return (
                          <div
                            key={step.id}
                            className={`flex items-center space-x-3 p-3 rounded-lg ${
                              isActive ? "bg-gray-800/50" : ""
                            } transition-all duration-200`}
                          >
                            <Icon
                              size={18}
                              className={`${step.color} ${
                                isActive && step.id === 3 ? "animate-spin" : ""
                              }`}
                            />
                            <span className="text-sm text-gray-300">
                              {step.text}
                            </span>
                            {!isActive && step.id < currentAction?.id && (
                              <Check
                                size={18}
                                className="text-green-400 ml-auto"
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <button
                    type="submit"
                    disabled={!userInput.trim() || isProcessing}
                    className="w-full py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg flex items-center justify-center space-x-2 hover:from-indigo-500 hover:to-purple-500 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isProcessing ? (
                      <>
                        <Loader size={18} className="animate-spin" />
                        <span>Processing...</span>
                      </>
                    ) : (
                      <>
                        <span>Continue</span>
                        <ArrowRight size={18} />
                      </>
                    )}
                  </button>
                </form>
              ) : (
                <div className="text-center space-y-6">
                  <div className="relative">
                    <div className="w-16 h-16 mx-auto rounded-full bg-gradient-to-r from-green-500 to-emerald-500 flex items-center justify-center">
                      <Check size={32} className="text-white" />
                    </div>
                    {showSparkles && (
                      <>
                        <Sparkles
                          size={20}
                          className="absolute top-0 right-1/3 text-yellow-400 animate-bounce"
                        />
                        <Sparkles
                          size={16}
                          className="absolute bottom-0 left-1/3 text-yellow-400 animate-bounce delay-100"
                        />
                      </>
                    )}
                  </div>
                  <div className="bg-gray-800/50 rounded-lg p-4 space-y-2">
                    <h3 className="text-lg font-medium text-white">
                      Schedule Optimized!
                    </h3>
                    <p className="text-gray-400 text-sm">
                      Your calendar has been reorganized for better
                      productivity.
                    </p>
                  </div>
                  <button
                    onClick={resetState}
                    className="px-6 py-2 text-indigo-400 hover:text-indigo-300 transition-colors rounded-lg hover:bg-gray-800"
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AIAssistant;
