import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeft,
  Moon,
  Sunrise,
  Coffee,
  Sun,
  Book,
  Apple,
  BedDouble,
  Clock,
  Users,
  Activity,
  Droplets,
  BookOpen,
  Eye,
  AlarmClockOff,
} from "lucide-react";

const SunnahRoutines = ({ onToggleRoutine, activeRoutines = [] }) => {
  const navigate = useNavigate();

  // Map icon names to components
  const iconComponents = {
    Moon,
    Sunrise,
    Coffee,
    Sun,
    Book,
    Apple,
    BedDouble,
    Clock,
    Users,
    Activity,
    Droplets,
    BookOpen,
    Eye,
    AlarmClockOff,
  };

  const initialRoutines = [
    {
      id: 1,
      name: "Last Third of the Night",
      iconName: "Moon",
      active: false,
      time: "03:00",
      duration: 60,
    },
    {
      id: 2,
      name: "Use Miswaak",
      iconName: "Sunrise",
      active: false,
      time: "05:00",
      duration: 5,
    },
    {
      id: 3,
      name: "Drink Honey Water",
      iconName: "Coffee",
      active: false,
      time: "06:00",
      duration: 5,
    },
    {
      id: 4,
      name: "Pray Ishraq",
      iconName: "Sun",
      active: false,
      time: "06:30",
      duration: 15,
    },
    {
      id: 5,
      name: "Morning Adhkar",
      iconName: "Book",
      active: false,
      time: "07:00",
      duration: 15,
    },
    {
      id: 6,
      name: "Eat 7 Dates",
      iconName: "Apple",
      active: false,
      time: "07:30",
      duration: 5,
    },
    {
      id: 7,
      name: "Pray Duha",
      iconName: "Sun",
      active: false,
      time: "09:30",
      duration: 15,
    },
    {
      id: 8,
      name: "Midday Nap",
      iconName: "BedDouble",
      active: false,
      time: "13:00",
      duration: 30,
    },
    {
      id: 9,
      name: "Saa'at Al Istijaabah",
      iconName: "Clock",
      active: false,
      time: "16:30",
      duration: 30,
    },
    {
      id: 10,
      name: "Du'a Before Meetings",
      iconName: "Users",
      active: false,
      time: "09:00",
      duration: 5,
    },
    {
      id: 11,
      name: "Walk After Meal",
      iconName: "Activity",
      active: false,
      time: "13:45",
      duration: 15,
    },
    {
      id: 12,
      name: "Prepare Date Water",
      iconName: "Droplets",
      active: false,
      time: "17:00",
      duration: 5,
    },
    {
      id: 13,
      name: "Evening Adhkar",
      iconName: "BookOpen",
      active: false,
      time: "17:30",
      duration: 15,
    },
    {
      id: 14,
      name: "Apply Kohl",
      iconName: "Eye",
      active: false,
      time: "21:00",
      duration: 5,
    },
    {
      id: 15,
      name: "1/6th Night Nap",
      iconName: "AlarmClockOff",
      active: false,
      time: "21:30",
      duration: 120,
    },
  ];

  // Initialize routines state with active states from activeRoutines prop
  const [routines, setRoutines] = useState(() => {
    return initialRoutines.map((routine) => ({
      ...routine,
      active: activeRoutines.some(
        (activeRoutine) => activeRoutine.id === `sunnah_${routine.id}`
      ),
    }));
  });

  // Update routines when activeRoutines prop changes
  useEffect(() => {
    setRoutines((prevRoutines) =>
      prevRoutines.map((routine) => ({
        ...routine,
        active: activeRoutines.some(
          (activeRoutine) => activeRoutine.id === `sunnah_${routine.id}`
        ),
      }))
    );
  }, [activeRoutines]);

  const calculateEndTime = (startTime, durationMinutes) => {
    const [hours, minutes] = startTime.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes + durationMinutes;
    const endHours = Math.floor(totalMinutes / 60) % 24;
    const endMinutes = totalMinutes % 60;
    return `${String(endHours).padStart(2, "0")}:${String(endMinutes).padStart(
      2,
      "0"
    )}`;
  };

  const handleToggle = (id) => {
    setRoutines((prevRoutines) => {
      const updatedRoutines = prevRoutines.map((routine) =>
        routine.id === id ? { ...routine, active: !routine.active } : routine
      );

      const routine = updatedRoutines.find((r) => r.id === id);
      if (routine) {
        onToggleRoutine({
          id: `sunnah_${routine.id}`,
          title: routine.name,
          start: routine.time,
          end: calculateEndTime(routine.time, routine.duration),
          color: "bg-emerald-600",
          description: "Sunnah Routine",
          active: routine.active,
        });
      }

      return updatedRoutines;
    });
  };

  const renderIcon = (iconName) => {
    const IconComponent = iconComponents[iconName];
    return IconComponent ? <IconComponent size={16} /> : null;
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 p-4">
      <header className="flex justify-between items-center mb-6 text-white">
        <ChevronLeft
          size={24}
          className="cursor-pointer"
          onClick={() => navigate("/")}
        />
        <h1 className="text-xl font-bold">Sunnah Routines</h1>
        <div className="w-6" />
      </header>

      <div className="space-y-3">
        {routines.map((routine) => (
          <div
            key={routine.id}
            className="bg-gray-800 rounded-lg p-4 flex items-center justify-between"
          >
            <div className="flex items-center space-x-3">
              <div className="text-emerald-400">
                {renderIcon(routine.iconName)}
              </div>
              <div>
                <div className="text-white font-medium">{routine.name}</div>
                <div className="text-sm text-gray-400">{routine.time}</div>
              </div>
            </div>
            <button
              onClick={() => handleToggle(routine.id)}
              className={`w-12 h-6 flex items-center ${
                routine.active ? "bg-emerald-600" : "bg-gray-600"
              } rounded-full p-1 transition-colors duration-300 ease-in-out`}
            >
              <div
                className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
                  routine.active ? "translate-x-6" : ""
                }`}
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SunnahRoutines;
