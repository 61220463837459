import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  Plus,
  Calendar,
  Command,
  Keyboard,
  Clock,
  Heart,
  Sunrise,
} from "lucide-react";
import PrayerSettings from "./PrayerSettings";

const Sidebar = ({ onAddEvent, onPrayerSettingChange }) => {
  const navigate = useNavigate();

  return (
    <div
      className="hidden lg:flex w-80 border-r border-gray-800 h-screen flex-col"
      style={{ backgroundColor: "#041A34" }}
    >
      <div className="p-4">
        {/* Search Bar */}
        <div className="relative mb-6">
          <Search
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
            size={16}
          />
          <input
            type="text"
            placeholder="Search events..."
            className="w-full bg-gray-800/50 text-gray-200 pl-10 pr-4 py-2 rounded-md border border-gray-700/50 focus:outline-none focus:border-indigo-500 text-sm"
          />
        </div>

        {/* Add Event Button */}
        <button
          onClick={onAddEvent}
          className="w-full bg-gray-800/30 hover:bg-gray-800/50 text-white rounded-lg p-3 flex items-center justify-between mb-6 transition-colors duration-200"
        >
          <div className="flex items-center">
            <Plus size={16} className="mr-2" />
            <span className="text-sm">Add Event</span>
          </div>
          <div className="text-xs text-gray-400">⌘N</div>
        </button>

        {/* Prayer Settings */}
        <div className="mb-6">
          <PrayerSettings onSelectionChange={onPrayerSettingChange} />
        </div>

        {/* Sunnah Routines Button */}
        <button
          onClick={() => navigate("/sunnah-routines")}
          className="w-full bg-gray-800/30 hover:bg-gray-800/50 text-white rounded-lg p-3 flex items-center justify-between mb-6 transition-colors duration-200"
        >
          <div className="flex items-center">
            <Sunrise size={16} className="mr-2" />
            <span className="text-sm">Sunnah Routines</span>
          </div>
          <div className="text-xs text-gray-400">⌘S</div>
        </button>

        {/* Goals and Intention Button */}
        <button
          onClick={() => navigate("/goals")}
          className="w-full bg-gray-800/30 hover:bg-gray-800/50 text-white rounded-lg p-3 flex items-center justify-between mb-6 transition-colors duration-200"
        >
          <div className="flex items-center">
            <Heart size={16} className="mr-2" />
            <span className="text-sm">Goals and Intention</span>
          </div>
          <div className="text-xs text-gray-400">⌘G</div>
        </button>

        {/* Time Audit Button */}
        <button
          onClick={() => navigate("/time-audit")}
          className="w-full bg-gray-800/30 hover:bg-gray-800/50 text-white rounded-lg p-3 flex items-center justify-between mb-6 transition-colors duration-200"
        >
          <div className="flex items-center">
            <Clock size={16} className="mr-2" />
            <span className="text-sm">Time Audit</span>
          </div>
          <div className="text-xs text-gray-400">⌘T</div>
        </button>
      </div>

      {/* Keyboard Shortcuts */}
      <div className="mt-auto p-4 border-t border-gray-800/50">
        <h3 className="text-gray-300 text-sm font-medium mb-3">
          Useful Shortcuts
        </h3>
        <div className="space-y-2">
          <div className="flex items-center justify-between text-sm">
            <div className="flex items-center text-gray-400">
              <Command size={16} className="mr-2" />
              <span>Command menu</span>
            </div>
            <kbd className="px-2 py-1 bg-gray-800/30 text-gray-400 rounded text-xs">
              ⌘K
            </kbd>
          </div>
          <div className="flex items-center justify-between text-sm">
            <div className="flex items-center text-gray-400">
              <Calendar size={16} className="mr-2" />
              <span>Go to date</span>
            </div>
            <kbd className="px-2 py-1 bg-gray-800/30 text-gray-400 rounded text-xs">
              G
            </kbd>
          </div>
          <div className="flex items-center justify-between text-sm">
            <div className="flex items-center text-gray-400">
              <Keyboard size={16} className="mr-2" />
              <span>View all shortcuts</span>
            </div>
            <kbd className="px-2 py-1 bg-gray-800/30 text-gray-400 rounded text-xs">
              ?
            </kbd>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
