import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, Moon, Sun, Activity, ChevronRight } from "lucide-react";

const Protocols = ({ activeProtocols, onToggleProtocol }) => {
  const navigate = useNavigate();
  const [activeProtocol, setActiveProtocol] = useState(null);
  const [protocols, setProtocols] = useState([
    { id: 1, name: "Prayer Times", icon: <Moon size={24} />, active: false },
    { id: 2, name: "Sun Exposure", icon: <Sun size={24} />, active: false },
    { id: 3, name: "Exercise", icon: <Activity size={24} />, active: false },
  ]);

  useEffect(() => {
    setProtocols((prevProtocols) =>
      prevProtocols.map((protocol) => ({
        ...protocol,
        active: activeProtocols[protocol.name],
      }))
    );
  }, [activeProtocols]);

  const toggleProtocol = (id) => {
    const protocol = protocols.find((p) => p.id === id);
    if (protocol) {
      onToggleProtocol(protocol.name, !protocol.active);
    }
  };

  const renderHeader = () => (
    <header className="flex justify-between items-center mb-6 text-white">
      <ChevronLeft
        size={24}
        className="cursor-pointer"
        onClick={() => navigate("/")}
      />
      <h1 className="text-xl font-bold">Protocols</h1>
      <div className="w-6" />
    </header>
  );

  const renderProtocolList = () => (
    <div className="space-y-4">
      {protocols.map((protocol) => (
        <div
          key={protocol.id}
          className="bg-gray-800 rounded-lg shadow p-4 flex items-center justify-between"
        >
          <div className="flex items-center">
            <div className="text-white">{protocol.icon}</div>
            <span className="ml-3 font-semibold text-white">
              {protocol.name}
            </span>
          </div>
          <div className="flex items-center">
            <button
              onClick={() => toggleProtocol(protocol.id)}
              className={`w-12 h-6 flex items-center ${
                protocol.active ? "bg-indigo-600" : "bg-gray-400"
              } rounded-full p-1 transition-colors duration-300 ease-in-out`}
            >
              <div
                className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
                  protocol.active ? "translate-x-6" : ""
                }`}
              />
            </button>
            <ChevronRight
              size={24}
              className="ml-2 text-gray-400 cursor-pointer"
              onClick={() => setActiveProtocol(protocol)}
            />
          </div>
        </div>
      ))}
    </div>
  );

  const renderProtocolDetails = () => (
    <div className="space-y-4 text-white">
      <div className="flex items-center mb-4">
        <ChevronLeft
          size={24}
          className="mr-2 cursor-pointer"
          onClick={() => setActiveProtocol(null)}
        />
        <h2 className="text-xl font-bold">{activeProtocol.name}</h2>
      </div>

      <div className="bg-gray-800 rounded-lg shadow p-4">
        <h3 className="font-semibold mb-2">Settings</h3>
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span>Notifications</span>
            <button
              className={`w-12 h-6 flex items-center ${
                activeProtocol.active ? "bg-indigo-600" : "bg-gray-400"
              } rounded-full p-1 transition-colors duration-300 ease-in-out`}
            >
              <div
                className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
                  activeProtocol.active ? "translate-x-6" : ""
                }`}
              />
            </button>
          </div>
          <div className="flex justify-between items-center">
            <span>Show in Calendar</span>
            <button
              className={`w-12 h-6 flex items-center bg-gray-400 rounded-full p-1 transition-colors duration-300 ease-in-out`}
            >
              <div className="bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ease-in-out" />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-gray-800 rounded-lg shadow p-4">
        <h3 className="font-semibold mb-2">Customize</h3>
        <div className="space-y-2">
          {activeProtocol.name === "Prayer Times" && (
            <>
              <div className="flex justify-between items-center">
                <span>Calculation Method</span>
                <select className="bg-gray-700 text-white border border-gray-600 rounded p-1">
                  <option>Muslim World League</option>
                  <option>Islamic Society of North America</option>
                </select>
              </div>
              <div className="flex justify-between items-center">
                <span>Juristic Method</span>
                <select className="bg-gray-700 text-white border border-gray-600 rounded p-1">
                  <option>Standard</option>
                  <option>Hanafi</option>
                </select>
              </div>
            </>
          )}
          {activeProtocol.name === "Sun Exposure" && (
            <div className="flex justify-between items-center">
              <span>Preferred Time</span>
              <select className="bg-gray-700 text-white border border-gray-600 rounded p-1">
                <option>Morning</option>
                <option>Afternoon</option>
              </select>
            </div>
          )}
          {activeProtocol.name === "Exercise" && (
            <div className="flex justify-between items-center">
              <span>Exercise Duration</span>
              <select className="bg-gray-700 text-white border border-gray-600 rounded p-1">
                <option>30 minutes</option>
                <option>45 minutes</option>
                <option>60 minutes</option>
              </select>
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-800 rounded-lg shadow p-4">
        <h3 className="font-semibold mb-2">Information</h3>
        <p className="text-sm text-gray-400">
          Learn about the benefits and Islamic significance of this protocol.
        </p>
        <button className="mt-2 text-indigo-400">Read More</button>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 p-4">
      {renderHeader()}
      <div className="flex-1 overflow-y-auto">
        {activeProtocol ? renderProtocolDetails() : renderProtocolList()}
      </div>
    </div>
  );
};

export default Protocols;
