import React, { useState, useEffect } from "react";
import { Edit2, Trash2 } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import AppUsageTooltip from "./AppUsageTooltip";

const EventList = ({ events, onEditEvent, onDeleteEvent, isAIProcessing }) => {
  const [tooltipEvent, setTooltipEvent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [visibleEvents, setVisibleEvents] = useState([]);

  // Constants for layout
  const HOUR_HEIGHT = 60;
  const HEADER_OFFSET = 30;
  const ANIMATION_DELAY = 200;

  useEffect(() => {
    // If AI is processing, animate events one by one
    if (isAIProcessing) {
      setVisibleEvents([]);
      events.forEach((event, index) => {
        setTimeout(() => {
          setVisibleEvents((prev) => [...prev, event]);
        }, index * ANIMATION_DELAY);
      });
    } else {
      // If not processing, show all events immediately
      setVisibleEvents(events);
    }
  }, [events, isAIProcessing]);

  const parseTime = (time) => {
    if (typeof time === "string") {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    }
    return typeof time === "number" ? time : 0;
  };

  const isPrayerEvent = (event) => {
    if (!event || !event.id) return false;
    return String(event.id).startsWith("prayer_");
  };

  const handleMouseMove = (event, e) => {
    setTooltipPosition({ x: e.clientX, y: e.clientY });
    setTooltipEvent(event);
  };

  const handleMouseLeave = () => {
    setTooltipEvent(null);
  };

  const calculateEventPositions = () => {
    const validEvents = visibleEvents.filter(
      (event) => event && typeof event === "object" && event.start && event.end
    );

    const sortedEvents = [...validEvents].sort((a, b) => {
      const aStart = parseTime(a.start);
      const bStart = parseTime(b.start);
      return aStart - bStart;
    });

    const positionedEvents = [];
    const columns = [];

    sortedEvents.forEach((event) => {
      const start = parseTime(event.start);
      const end = parseTime(event.end);

      let column = 0;
      while (columns[column] && columns[column] > start) {
        column++;
      }
      columns[column] = end;

      positionedEvents.push({
        ...event,
        column,
        startPosition: start,
        endPosition: end,
      });
    });

    const maxColumn = Math.max(...positionedEvents.map((e) => e.column), 0);

    return positionedEvents.map((event) => ({
      ...event,
      column: event.column,
      maxColumn: maxColumn,
    }));
  };

  const renderEvents = () => {
    const positionedEvents = calculateEventPositions();
    const timeColumnWidth = "3rem";
    const eventLeftOffset = "0.5rem";
    const rightSpacing = "1rem";
    const minEventHeight = 55;

    return (
      <AnimatePresence>
        {positionedEvents.map((event) => {
          if (!event || !event.id) return null;

          const duration = event.endPosition - event.startPosition;
          const height = Math.max(duration, minEventHeight);
          const width = `calc((100% - ${timeColumnWidth} - ${eventLeftOffset} - ${rightSpacing}) / ${
            event.maxColumn + 1
          })`;
          const left = `calc(${timeColumnWidth} + ${eventLeftOffset} + (${event.column} * ${width}))`;

          return (
            <motion.div
              key={event.id}
              initial={{ opacity: 0, x: -20, scale: 0.9 }}
              animate={{ opacity: 1, x: 0, scale: 1 }}
              exit={{ opacity: 0, x: 20, scale: 0.9 }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              className={`absolute ${
                event.color || "bg-indigo-600"
              } rounded-lg p-2 overflow-hidden cursor-pointer 
                hover:ring-2 hover:ring-white hover:ring-opacity-50 transition-all duration-200`}
              style={{
                top: `${event.startPosition + HEADER_OFFSET}px`,
                height: `${height}px`,
                left: left,
                width: width,
                minHeight: `${minEventHeight}px`,
              }}
              onMouseMove={(e) => handleMouseMove(event, e)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="flex flex-col h-full">
                <div className="flex-grow">
                  <div className="text-sm font-semibold text-white mb-1">
                    {event.title}
                  </div>
                  <div className="text-xs text-white/75">
                    {event.start} - {event.end}
                  </div>
                </div>
                {!isPrayerEvent(event) && (
                  <div className="flex space-x-1 justify-end">
                    <button
                      onClick={() => onEditEvent(event)}
                      className="p-1 bg-white bg-opacity-20 rounded hover:bg-opacity-30 transition-colors duration-200"
                      title="Edit event"
                    >
                      <Edit2 size={12} className="text-white" />
                    </button>
                    <button
                      onClick={() => onDeleteEvent(event.id)}
                      className="p-1 bg-white bg-opacity-20 rounded hover:bg-opacity-30 transition-colors duration-200"
                      title="Delete event"
                    >
                      <Trash2 size={12} className="text-white" />
                    </button>
                  </div>
                )}
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>
    );
  };

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0">
      {renderEvents()}
      {tooltipEvent && (
        <AppUsageTooltip event={tooltipEvent} position={tooltipPosition} />
      )}
    </div>
  );
};

export default EventList;
