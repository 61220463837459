import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChevronLeft,
  Clock,
  Zap,
  AlertTriangle,
  TrendingUp,
  BarChart2,
} from "lucide-react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const TimeAudit = ({ events }) => {
  const navigate = useNavigate();
  // Updated category names to be shorter
  const [weeklyStats, setWeeklyStats] = useState([
    { category: "Work", hours: 40 },
    { category: "Pray", hours: 5 },
    { category: "Gym", hours: 3 },
    { category: "Learn", hours: 8 },
    { category: "Social", hours: 6 },
    { category: "Rest", hours: 56 },
    { category: "Other", hours: 10 },
  ]);
  const [insights, setInsights] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    analyzeEvents();
  }, [events]);

  const analyzeEvents = () => {
    setInsights([
      {
        icon: <Clock className="text-blue-400" />,
        title: "Peak Productivity",
        description: "Your most productive hours are between 9 AM and 11 AM",
      },
      {
        icon: <Zap className="text-yellow-400" />,
        title: "Prayer Consistency",
        description:
          "You have maintained consistent prayer times throughout the week",
      },
      {
        icon: <AlertTriangle className="text-red-400" />,
        title: "Extended Screen Time",
        description: "Screen time increases significantly after 9 PM",
      },
    ]);

    setSuggestions([
      {
        icon: <TrendingUp className="text-green-400" />,
        title: "Schedule Optimization",
        description:
          "Consider scheduling important tasks during your peak productivity hours (9-11 AM)",
      },
      {
        icon: <BarChart2 className="text-purple-400" />,
        title: "Health Balance",
        description: "Add 2 more exercise sessions to meet your weekly goal",
      },
    ]);
  };

  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#9CA3AF">
          {payload.value}
        </text>
      </g>
    );
  };

  const renderBarChart = () => {
    if (!weeklyStats || weeklyStats.length === 0) {
      return <div className="text-gray-400">No data available</div>;
    }

    return (
      <ResponsiveContainer width="100%" height={240}>
        <BarChart
          data={weeklyStats}
          margin={{ top: 20, right: 30, left: 50, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
          <XAxis
            dataKey="category"
            stroke="#9CA3AF"
            height={40}
            interval={0}
            tickLine={false}
            tick={<CustomXAxisTick />}
          />
          <YAxis
            stroke="#9CA3AF"
            tick={{ fill: "#9CA3AF" }}
            label={{
              value: "Hours per Week",
              angle: -90,
              position: "center",
              fill: "#9CA3AF",
              dx: -40,
            }}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "#1F2937",
              border: "none",
              borderRadius: "0.375rem",
              color: "#fff",
            }}
            cursor={{ fill: "rgba(107, 114, 128, 0.1)" }}
          />
          <Bar dataKey="hours" fill="#4F46E5" radius={[4, 4, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="min-h-screen p-6" style={{ backgroundColor: "#041A34" }}>
      <div className="flex items-center mb-6">
        <button
          onClick={() => navigate("/")}
          className="text-gray-400 hover:text-white mr-4"
        >
          <ChevronLeft size={24} />
        </button>
        <h1 className="text-2xl font-bold text-white">Time Audit Report</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-gray-800 rounded-lg p-4">
          <h2 className="text-lg font-semibold text-white mb-2">
            Weekly Time Distribution
          </h2>
          <div className="h-60">{renderBarChart()}</div>
        </div>

        <div className="bg-gray-800 rounded-lg p-4">
          <h2 className="text-lg font-semibold text-white mb-2">
            Key Insights
          </h2>
          <div className="space-y-2">
            {insights.map((insight, index) => (
              <div
                key={index}
                className="flex items-start space-x-3 bg-gray-700/50 rounded-lg p-3"
              >
                <div className="mt-1">{insight.icon}</div>
                <div>
                  <h3 className="text-white font-medium">{insight.title}</h3>
                  <p className="text-gray-400 text-sm">{insight.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="lg:col-span-2 bg-gray-800 rounded-lg p-4">
          <h2 className="text-lg font-semibold text-white mb-2">
            Recommendations
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className="flex items-start space-x-3 bg-gray-700/50 rounded-lg p-3"
              >
                <div className="mt-1">{suggestion.icon}</div>
                <div>
                  <h3 className="text-white font-medium">{suggestion.title}</h3>
                  <p className="text-gray-400 text-sm">
                    {suggestion.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeAudit;
