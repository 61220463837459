import React, { useState } from "react";
import { Plus, Moon, Heart, Clock, Sunrise, X } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";

const MobileBottomMenu = ({ onAddEvent, onPrayerSettingChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrayerSettings, setShowPrayerSettings] = useState(false);

  const isActive = (path) => location.pathname === path;

  const navigationItems = [
    {
      icon: Clock,
      label: "Time",
      path: "/time-audit",
    },
    {
      icon: Moon,
      label: "Prayer",
      action: () => setShowPrayerSettings(true),
      isActive: showPrayerSettings,
    },
    {
      icon: Plus,
      label: "Add",
      action: onAddEvent,
    },
    {
      icon: Sunrise,
      label: "Sunnah",
      path: "/sunnah-routines",
    },
    {
      icon: Heart,
      label: "Goals",
      path: "/goals",
    },
  ];

  const prayerSettingsOptions = [
    "Masjid Selection", // Reordered to be first
    "Geolocation", // Second
    "Custom Timetable", // Third, and renamed from just "Custom"
  ];

  return (
    <div className="lg:hidden">
      {/* Prayer Settings Modal */}
      {showPrayerSettings && (
        <div className="fixed inset-0 bg-gray-900/95 z-50 flex items-end">
          <div className="w-full p-4 bg-gray-800 rounded-t-xl">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-white">
                Prayer Settings
              </h3>
              <button
                onClick={() => setShowPrayerSettings(false)}
                className="text-gray-400 hover:text-white"
              >
                <X size={24} />
              </button>
            </div>
            <div className="space-y-3">
              {prayerSettingsOptions.map((option) => (
                <button
                  key={option}
                  onClick={() => {
                    onPrayerSettingChange(option.toLowerCase());
                    setShowPrayerSettings(false);
                  }}
                  className="w-full p-3 bg-gray-700 rounded-lg text-white text-left hover:bg-gray-600 transition-colors"
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Bottom Navigation */}
      <div className="fixed bottom-4 left-4 right-4 z-40">
        <div
          className="bg-gray-800/95 backdrop-blur-md border border-gray-700/50 rounded-full px-4 py-2 shadow-lg"
          style={{ boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)" }}
        >
          <div className="flex justify-around items-center">
            {navigationItems.map((item, index) => {
              const Icon = item.icon;
              const active = item.path ? isActive(item.path) : item.isActive;

              return (
                <button
                  key={index}
                  onClick={() =>
                    item.action ? item.action() : navigate(item.path)
                  }
                  className={`flex flex-col items-center px-3 py-2 rounded-full transition-all duration-200 ${
                    active ? "text-white" : "text-gray-400 hover:text-gray-200"
                  }`}
                >
                  <Icon size={22} />
                  <span className="text-xs mt-1">{item.label}</span>
                </button>
              );
            })}
          </div>
        </div>
      </div>

      {/* Bottom Spacing */}
      <div className="h-24" />
    </div>
  );
};

export default MobileBottomMenu;
