import React from "react";
import { X } from "lucide-react";

const MasjidSelectionModal = ({ onClose, onSelect }) => {
  const masjids = [
    { id: 1, name: "Masjid Al-Noor", location: "Downtown" },
    { id: 2, name: "Islamic Center", location: "West Side" },
    { id: 3, name: "Al-Iman Masjid", location: "North District" },
    { id: 4, name: "Al-Huda Center", location: "East Side" },
    { id: 5, name: "Al-Farooq Masjid", location: "South Area" },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-900 rounded-lg w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-white">Select Masjid</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-200 transition-colors"
          >
            <X size={24} />
          </button>
        </div>

        <div className="space-y-2 max-h-96 overflow-y-auto">
          {masjids.map((masjid) => (
            <button
              key={masjid.id}
              onClick={() => onSelect(masjid)}
              className="w-full text-left p-3 bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors duration-200"
            >
              <div className="text-white font-medium">{masjid.name}</div>
              <div className="text-gray-400 text-sm">{masjid.location}</div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MasjidSelectionModal;
