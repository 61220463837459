import React, { useState, useEffect } from "react";
import { Lock, Unlock, AlertCircle } from "lucide-react";

const PasswordProtection = ({ onUnlock }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [isLocked, setIsLocked] = useState(true);
  const [showError, setShowError] = useState(false);

  // Check if the app was previously unlocked in this session
  useEffect(() => {
    const wasUnlocked = sessionStorage.getItem("calendarUnlocked");
    if (wasUnlocked === "true") {
      setIsLocked(false);
      onUnlock();
    }
  }, [onUnlock]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace 'your-secure-password' with the actual password you want to use
    if (password === "early8d0PTER-solah") {
      setIsLocked(false);
      setError("");
      sessionStorage.setItem("calendarUnlocked", "true");
      onUnlock();
    } else {
      setAttempts((prev) => prev + 1);
      setError("Incorrect password");
      setShowError(true);
      setPassword("");

      // Hide error message after 3 seconds
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  if (!isLocked) return null;

  return (
    <div
      className="fixed inset-0 bg-gray-900/95 backdrop-blur-sm flex items-center justify-center z-50"
      style={{ backgroundColor: "#041A34" }}
    >
      <div className="w-full max-w-md p-8">
        <div className="text-center mb-8">
          <div className="w-16 h-16 bg-indigo-600/20 rounded-full flex items-center justify-center mx-auto mb-4 border border-indigo-500/30">
            <Lock size={24} className="text-indigo-400" />
          </div>
          <h1 className="text-2xl font-bold text-white mb-2">Welcome Back</h1>
          <p className="text-gray-400">
            Enter your password to access your calendar
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="relative">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full px-4 py-3 bg-gray-800/50 border ${
                showError ? "border-red-500" : "border-gray-700"
              } rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-colors`}
              placeholder="Enter password"
              autoFocus
            />
            {showError && (
              <div className="absolute -bottom-6 left-0 right-0 flex items-center justify-center text-sm text-red-400">
                <AlertCircle size={14} className="mr-1" />
                <span>{error}</span>
              </div>
            )}
          </div>

          <button
            type="submit"
            className="w-full mt-8 py-3 px-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg flex items-center justify-center space-x-2 hover:from-indigo-500 hover:to-purple-500 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-900 focus:ring-indigo-500"
          >
            <Unlock size={18} />
            <span>Unlock Calendar</span>
          </button>
        </form>

        {attempts > 2 && (
          <p className="mt-4 text-center text-sm text-gray-400">
            Having trouble? Contact your administrator for assistance.
          </p>
        )}
      </div>
    </div>
  );
};

export default PasswordProtection;
