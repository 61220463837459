import React from "react";

const Timeline = ({ currentTime }) => {
  const renderTimeline = () => (
    <div className="flex flex-col w-full">
      {[...Array(24)].map((_, index) => (
        <div key={index} className="relative h-[60px] flex items-center">
          <div className="absolute left-0 w-12 h-full flex items-center justify-end pr-2 text-xs text-gray-400">
            {index.toString().padStart(2, "0")}:00
          </div>
          <div className="absolute left-12 right-0 border-t border-gray-700" />
        </div>
      ))}
    </div>
  );

  const renderCurrentTimeLine = () => {
    const now = currentTime;
    const minutes = now.getHours() * 60 + now.getMinutes();
    const top = minutes; // 1px per minute

    return (
      <div
        className="absolute left-12 right-0 border-t border-red-500 z-10"
        style={{ top: `${top}px` }}
      >
        <div className="absolute left-0 w-2 h-2 bg-red-500 rounded-full -mt-1 -ml-1"></div>
      </div>
    );
  };

  return (
    <div className="relative" style={{ height: "1440px" }}>
      {" "}
      {/* 24 hours * 60px per hour */}
      {renderTimeline()}
      {renderCurrentTimeLine()}
    </div>
  );
};

export default Timeline;
