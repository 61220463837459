import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import CalendarHome from "./components/CalendarHome";
import Protocols from "./components/Protocols";
import EventModal from "./components/EventModal";
import GoalsTracking from "./components/GoalsTracking";
import TimeAudit from "./components/TimeAudit";
import SunnahRoutines from "./components/SunnahRoutines";

function App() {
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [events, setEvents] = useState([]);

  // Updated prayer times with proper durations
  const [prayerTimes, setPrayerTimes] = useState([
    {
      id: "prayer_fajr",
      title: "Fajr Prayer",
      start: "05:30",
      end: "06:00",
      color: "bg-indigo-600",
      description: "Dawn Prayer",
      isRequired: true,
    },
    {
      id: "prayer_sunrise",
      title: "Sunrise",
      start: "06:45",
      end: "07:00",
      color: "bg-yellow-500",
      description: "Sunrise Time",
      isRequired: false,
    },
    {
      id: "prayer_dhuhr",
      title: "Dhuhr Prayer",
      start: "12:30",
      end: "13:00",
      color: "bg-indigo-600",
      description: "Noon Prayer",
      isRequired: true,
    },
    {
      id: "prayer_asr",
      title: "Asr Prayer",
      start: "15:45",
      end: "16:15",
      color: "bg-indigo-600",
      description: "Afternoon Prayer",
      isRequired: true,
    },
    {
      id: "prayer_maghrib",
      title: "Maghrib Prayer",
      start: "18:15",
      end: "18:45",
      color: "bg-indigo-600",
      description: "Sunset Prayer",
      isRequired: true,
    },
    {
      id: "prayer_isha",
      title: "Isha Prayer",
      start: "19:45",
      end: "20:15",
      color: "bg-indigo-600",
      description: "Night Prayer",
      isRequired: true,
    },
  ]);

  const [showPrayerTimes, setShowPrayerTimes] = useState(false);

  const handlePrayerSettingChange = (setting) => {
    // Handle different prayer time calculation methods here
    if (setting === "none") {
      setShowPrayerTimes(false);
    } else {
      setShowPrayerTimes(true);
      // You could add logic here to fetch prayer times based on:
      // - Masjid selection
      // - Geolocation
      // - Custom timetable
    }
  };

  const handleEditEvent = (updatedEvent) => {
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === updatedEvent.id ? updatedEvent : event
      )
    );
  };

  const handleDeleteEvent = (eventId) => {
    setEvents((prevEvents) =>
      prevEvents.filter((event) => event.id !== eventId)
    );
  };

  const handleAddEvent = (newEvent) => {
    const eventToAdd = { ...newEvent, id: Date.now() };
    setEvents((prevEvents) => [...prevEvents, eventToAdd]);
    setIsEventModalOpen(false);
  };

  const allEvents = showPrayerTimes ? [...events, ...prayerTimes] : events;

  return (
    <Router>
      <div className="relative">
        <Routes>
          <Route
            path="/"
            element={
              <CalendarHome
                events={allEvents}
                onEditEvent={handleEditEvent}
                onDeleteEvent={handleDeleteEvent}
                onAddEvent={handleAddEvent}
                onPrayerSettingChange={handlePrayerSettingChange}
              />
            }
          />
          <Route path="/protocols" element={<Protocols />} />
          <Route path="/goals" element={<GoalsTracking />} />
          <Route
            path="/time-audit"
            element={<TimeAudit events={allEvents} />}
          />
          <Route path="/sunnah-routines" element={<SunnahRoutines />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {isEventModalOpen && (
          <EventModal
            event={null}
            onClose={() => setIsEventModalOpen(false)}
            onSave={handleAddEvent}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
