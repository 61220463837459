import React, { useState, useRef, useEffect } from "react";
import CalendarHeader from "./CalendarHeader";
import Timeline from "./Timeline";
import EventList from "./EventList";
import EventModal from "./EventModal";
import Sidebar from "./Sidebar";
import AIAssistant from "./AIAssistant";
import PasswordProtection from "./PasswordProtection";
import MobileBottomMenu from "./MobileBottomMenu";

const CalendarHome = ({
  events,
  onEditEvent,
  onDeleteEvent,
  onAddEvent,
  onPrayerSettingChange,
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [isAIProcessing, setIsAIProcessing] = useState(false);
  const [currentAIStep, setCurrentAIStep] = useState(null);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const scrollContainerRef = useRef(null);

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  // Initial scroll position setup
  useEffect(() => {
    if (scrollContainerRef.current && isUnlocked) {
      const currentHour = new Date().getHours();
      const scrollPosition = currentHour * 60;
      scrollContainerRef.current.scrollTop = scrollPosition - 120;
    }
  }, [isUnlocked]);

  const changeWeek = (direction) => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + direction * 7);
      return newDate;
    });
  };

  const handleEditEvent = (event) => {
    if (event.id.startsWith("prayer_")) return;
    setEditingEvent(event);
    setIsModalOpen(true);
  };

  const handleSaveEvent = (updatedEvent) => {
    if (editingEvent) {
      onEditEvent(updatedEvent);
    } else {
      onAddEvent(updatedEvent);
    }
    setIsModalOpen(false);
    setEditingEvent(null);
  };

  const handleAIProcessingChange = (processing) => {
    setIsAIProcessing(processing);
  };

  const handleAIStepChange = (step) => {
    setCurrentAIStep(step);
  };

  const handleAIEventsChange = (newEvents) => {
    newEvents.forEach((event, index) => {
      setTimeout(() => {
        onAddEvent(event);
      }, index * 300);
    });
  };

  const handleUnlock = () => {
    setIsUnlocked(true);
  };

  const handleOpenEventModal = () => {
    setIsModalOpen(true);
  };

  const handlePrayerSettingChange = (setting) => {
    onPrayerSettingChange(setting);
  };

  return (
    <>
      <PasswordProtection onUnlock={handleUnlock} />

      {isUnlocked && (
        <div
          className="flex h-screen text-white overflow-hidden"
          style={{ backgroundColor: "#041A34" }}
        >
          {/* Sidebar - hidden on mobile, shown on lg screens */}
          <div className="hidden lg:block">
            <Sidebar
              onAddEvent={handleOpenEventModal}
              onPrayerSettingChange={handlePrayerSettingChange}
            />
          </div>

          <div className="flex flex-col flex-1">
            <CalendarHeader currentDate={currentDate} changeWeek={changeWeek} />

            {/* Add bottom padding on mobile for the menu */}
            <div
              ref={scrollContainerRef}
              className="flex-1 overflow-y-auto relative pb-16 lg:pb-0"
            >
              <Timeline currentTime={currentTime} />

              <EventList
                events={events}
                onEditEvent={handleEditEvent}
                onDeleteEvent={onDeleteEvent}
                isAIProcessing={isAIProcessing}
                currentAIStep={currentAIStep}
              />
            </div>
          </div>

          {isModalOpen && (
            <EventModal
              event={editingEvent}
              onClose={() => {
                setIsModalOpen(false);
                setEditingEvent(null);
              }}
              onSave={handleSaveEvent}
            />
          )}

          <AIAssistant
            onProcessingStateChange={handleAIProcessingChange}
            onEventsChange={handleAIEventsChange}
            onStepChange={handleAIStepChange}
          />

          {/* Mobile Bottom Menu */}
          <MobileBottomMenu
            onAddEvent={handleOpenEventModal}
            onPrayerSettingChange={handlePrayerSettingChange}
          />
        </div>
      )}
    </>
  );
};

export default CalendarHome;
