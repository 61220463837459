import React, { useRef, useEffect, useState } from "react";
import { Youtube, MessageCircle, Chrome } from "lucide-react";

const AppUsageTooltip = ({ event, position }) => {
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      setTooltipHeight(tooltipRef.current.offsetHeight);
    }
  }, [event]); // Recalculate when event changes

  // Fixed app usage patterns with time ranges
  const appUsagePatterns = [
    [
      {
        name: "YouTube",
        icon: Youtube,
        color: "text-red-500",
        minutes: 21,
        timeRange: "07:22 - 07:43",
        percentage: 40,
      },
      {
        name: "Messages",
        icon: MessageCircle,
        color: "text-green-500",
        minutes: 3,
        timeRange: "07:42 - 07:45",
        percentage: 15,
      },
      {
        name: "Chrome",
        icon: Chrome,
        color: "text-blue-400",
        minutes: 12,
        timeRange: "07:45 - 07:57",
        percentage: 25,
      },
    ],
    [
      {
        name: "Messages",
        icon: MessageCircle,
        color: "text-green-500",
        minutes: 15,
        timeRange: "08:00 - 08:15",
        percentage: 30,
      },
      {
        name: "YouTube",
        icon: Youtube,
        color: "text-red-500",
        minutes: 18,
        timeRange: "08:15 - 08:33",
        percentage: 35,
      },
      {
        name: "Chrome",
        icon: Chrome,
        color: "text-blue-400",
        minutes: 8,
        timeRange: "08:33 - 08:41",
        percentage: 20,
      },
    ],
  ];

  // Get app usage pattern based on event
  const getAppUsage = (event) => {
    const patternIndex =
      typeof event.id === "number" ? event.id % appUsagePatterns.length : 0;
    return appUsagePatterns[patternIndex];
  };

  if (!event) return null;

  const appUsage = getAppUsage(event);

  return (
    <div
      ref={tooltipRef}
      className="fixed z-50 bg-gray-900/95 text-white rounded-lg shadow-lg min-w-[220px] overflow-hidden border border-gray-800"
      style={{
        left: position.x,
        top: `${position.y - tooltipHeight}px`, // Position tooltip above cursor by its height
        pointerEvents: "none",
      }}
    >
      <div className="p-3 border-b border-gray-800">
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium">Time Usage Analysis</span>
          <span className="text-xs text-red-400">40%</span>
        </div>
      </div>
      <div className="space-y-1 p-1">
        {appUsage.map((app, index) => {
          const Icon = app.icon;
          return (
            <div key={index} className="p-2 hover:bg-gray-800/50 rounded">
              <div className="flex items-center justify-between mb-1">
                <div className="flex items-center">
                  <Icon size={14} className={`mr-2 ${app.color}`} />
                  <span className="text-sm text-gray-300">{app.name}</span>
                </div>
                <span className="text-xs text-gray-400">{app.minutes}m</span>
              </div>
              <div className="text-xs text-gray-500">{app.timeRange}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AppUsageTooltip;
